$thumbnailWidth: 400px;
$thumbnailWidthMobile: 300px;


$menuPaddingSides: 2em;
$menuPaddinSidesShrink: 4px;

$menuAnimDuration: 0.3s;

$navbarHeight: 160px;
$navbarMarginSides: 3em;
$logoWidth: 60px;
$logoWidthShring: 40px;
