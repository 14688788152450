@import "/home/waldez/petraslaba.com/assets/css/variables/colors.scss";
@import "/home/waldez/petraslaba.com/assets/css/variables/dimensions.scss";


html {
	scroll-behavior: smooth;
}

body {
	background-color: $background;
	color: $fontColor;
	font-family: "Montserrat", serif;
	font-weight: 400;
	font-style: normal;
	padding: 0;
	margin: 0;
	width: 100%;
}

#fp {
	margin-bottom: 4em;
}

#navbar {
	/* overflow: hidden; */
	background-color: #fff;
	transition: $menuAnimDuration;
	position: fixed;
	height: $navbarHeight;

	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 18px;
	top: 0;
	z-index: 99;
}

#navbar a {
	float: left;
	color: $fontColor;
	text-align: center;
	padding: 12px;
	text-decoration: none;
	line-height: 25px;
	border-radius: 4px;

	font-size: medium;
    font-weight: 600;
}

#navbar #logo {
	margin-left: 0;
	font-weight: bold;
	transition: $menuAnimDuration;
}

#navbar a:hover {
	color: $linkHover;
}

#navbar a.active {
	background-color: dodgerblue;
	color: white;
}

#navbar-left {
	margin-left: 5em;
}

.frontpage #navbar-right {
	margin-right: 5em;
}

.bio #navbar-right {
	margin-right: 5em;
}

.frontpage #navbar > #navbar-left a {
	padding: 0;
}


#navbar-right {
	transition: $menuAnimDuration;
}

#navbar-scale {
	transition: $menuAnimDuration;
}

.shrink #navbar-scale {
	transition: $menuAnimDuration;
}

#navbar.shrink {
	padding: 0;
	height: 64px;
	transition: $menuAnimDuration;
}

.submenu-content {
	display: none;
	position: absolute;
	background-color: white;
	min-width: 160px;
	border-radius: 4px;
	z-index: 1;
}

#navbar .submenu-content a {
	float: none;
	display: block;
}

h2 {
	margin-top: 2em;
	font-weight: 600;
	font-size: $h2FontSize;
}

.show {
	display: block !important;
}

#fp_img_container {
	height: 616px;
	overflow: hidden;
	background-image: url("/assets/img/frontpage.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;

	transition: 600ms;
	transition-timing-function: ease-out;
}

/* --- frontpage img ------*/
div.frontpage {
	padding: 0;
	margin: 0;
	width: 100%;
	height: auto;
}

img.frontpage {
	padding: 0;
	margin: 0;
	width: 100%;
	height: auto;
}
/* -------------------------------- */

.TODO {
	font-weight: bold !important;
	color: red !important;
}

.clearfix {
	overflow: auto;
}

.body {
	padding: 0;
	margin: 0 0 3em 0;
	width: 100%;
	transition: top $menuAnimDuration ease, position $menuAnimDuration ease;
}

button > span {
	display: inline-block;
	vertical-align: top;
}

button:after {
	content: "";
	display: inline-block;
	vertical-align: top;
	height: 100%;
}

#content {
	top: 0;
	padding-top: $navbarHeight;
	position: relative;
}

button:focus {
	outline: none;
}

// bio
#bio img {
	width: 50%;
	float: left;
}

#bio > div {
	width: 45%;
	display: inline-block;
}

#bio a img {
	width: 40px;
	margin-right: 1em;
}

#bio > div > p.copyright {
	clear: both;
	top: 1em;
	position: relative;
}

a#logo {
	position: relative;
	background-color: transparent;
}

a#logo:hover {
	background-color: transparent;
}

a#logo > img {
	width: $logoWidth;
	height: auto;

	transition: width $menuAnimDuration ease;
}

.shrink a#logo > img {
	width: $logoWidthShring;

	transition: width $menuAnimDuration ease-out;
}

.frontpage a#logo > img#static_logo {
	margin-top: 100%;
}

.frontpage a#logo > img#anim_logo {
	margin-top: 100%;
}


a#logo > img#static_logo {
	display: block;
}

a#logo > img#anim_logo {
	display: none;
	// top: -136px;
}

a#logo:hover > img#anim_logo {
	display: block;
}

a#logo:hover > img#static_logo {
	display: none;
}

iframe#loader {
	width: 100%;
	display: none;
}

div.article img {
    height: auto;
    max-width: 1280px;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

div.article_container {
	width: 100%;
	overflow: auto;
	overflow: visible;
}

div.hidden_container {
	display: none;
}

.personalie {
	text-transform: lowercase;
	margin: 2em 0 2em 0;
}

.center {
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.portfolio > .columns_conatiner {
	text-align: center;
}

.article {
	width: 100%;
}

.article .article_head {
	text-align: left;

	max-width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.article .article_head span.h2 {
	font-weight: 600;
	font-size: $h2FontSize;
}


.article .article_item {
	max-width: 80%;
	margin-left: auto;
	margin-right: auto;

	padding-bottom: 2em;
}

.wrapper {
	display: inline-block;
	padding-bottom: 2em;
}
.child {
	display: flex;
}

.child div {
	flex-grow: 1;
	width: 0;
}

.portfolio {
	background-color: $background;
	transition: top 1s;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;

	top: 0px;
}

.portfolio .column {
	width: $thumbnailWidth;
	display: inline-block;
	vertical-align: top;
	margin: 0 10px 0 0;
}

.portfolio .article_preview {
	margin: 10px 0 0 0;
	overflow: hidden;
	position: relative;
}

.article_preview a {
	color: white;
	display: block;
	top: 0;
	left: 0;
	margin-bottom: -4px;
}

.article_preview a .underlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: background-color $thumbOverlayAnimDuration;
}

.article_preview a:hover .underlay {
	background-color: black;
}

.article_preview a div.thumbnail {
	transition: opacity $thumbOverlayAnimDuration, transform $thumbOverlayAnimDuration;
	height: $thumbnailWidth;

	background-size: 105%;
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;
}

.article_preview a img.thumbnail {
	transition: opacity $thumbOverlayAnimDuration, transform $thumbOverlayAnimDuration;
	min-height: 200px;

	background-image: url(/assets/img/loading.gif);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #DDD;
}

.thumbnail {
	width: $thumbnailWidth;
}

.article_preview a:hover div.thumbnail {
	opacity: $thumbOverlayOpacity;
	transform: scale($thumbOverlayScale, $thumbOverlayScale);
}

.article_preview a:hover img.thumbnail {
	opacity: $thumbOverlayOpacity;
	transform: scale($thumbOverlayScale, $thumbOverlayScale);
}

.article_preview a .text {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	text-align: center;
	transition: opacity $thumbOverlayAnimDuration;
	color: white;
	opacity: 0;
}

.article_preview a .text p {
	color: white;
}

.article_preview a .text p:last-child::after {
  content: '...';
  display: inline-block;
}

.article_preview a:hover .text {
	opacity: 1;
}

// typography
p {
	color: $fontColor;
	font-size: $commonTextSize;
	margin-bottom: 10px;
	margin-top: 0px;
}

p a {
	color: $fontColor;
	font-style: italic;
}

a {
	color: $fontColor;
	text-decoration: none;
}

a:hover {
	color: $linkHover;
}

.huge {
	font-size: 75px;
	font-weight: 100;
}

// lists
ul {
	text-align: left;
}

li {
	list-style-type: none;
}

// MEDIA - mobile - needs to be here!
// related - but invisible, when no in media
.mobile {
	display: none;
}

.not-mobile {
	display: block;
}

.topmost-fixed {
	position: fixed;
    z-index: 1000;
}

.scroll_top {
	color: white;
	background-color: rgba(0, 0, 0, 0.3);
	text-align: center;
	font-weight: bold;
	font-size: 1.5em;
	line-height: 2em;

	width: 2em;
	height: 2em;
	right: 0.5em;
	bottom: 0.5em;

	border-radius: 1em;
}

.menu {
	top: 0;
	left: 0;
	background-color: white;
	position: fixed;
	width: 100%;
	top: 0;
	right: 0;
	z-index: 101;
}

#menu_button {
	width: 32px;
	right: 0px;
	border: 2px solid white;
	border-radius: 16px;
	background-color: white;
	margin: 4px;
	opacity: 0.75;
}

// @media screen and (max-width: 768px) {
@media screen and (max-width: 989px) {

	.child {
		margin-right: 1em;
		margin-left: 1em;
	}

	#navbar_mobile {
		display: none;
		right: 0;
		background-color: white;
		width: 60%;

		padding: 1em;
		font-weight: 600;
		line-height: 2em;height: 100%;
		border-left: 300px solid rgba(219, 114, 134, 0.5);
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
	}

	#navbar_mobile .social a {
		display: inline;
	}

	.menu div {
		position: absolute;
		right: 0;
	}

	.portfolio .column {
		margin: 0;
	}

	.menu img {
		display: block;
	}

	#fp_img_container {
		height: 70vh;
		background-size: 300%;
	}

	#navbar_mobile a {
		display: block;
	}

	.mobile {
		display: block;
	}

	.not-mobile {
		display: node;
	}

	.thumbnail {
		width: $thumbnailWidthMobile;
	}

	.portfolio {
		margin: 0 1em 0 1em;
	}

	.portfolio .column {
		width: $thumbnailWidthMobile;
	}

	#content {
		padding-top: $logoWidthShring;
	}

	#navbar {
		display: none;
	}

	a#logo > img {
		width: 86px;
	}

	.content {
		margin-left: 0;
	}

	#menu_button {
		display: inherit;
	}

	#bio img {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}

	#bio > div {
		width: 90%;
		margin: 1em;
	}
}

