$scheme: (#505160, #2980B9, #359fdc);

$background: #fff;
$fontColor: #160e1b;

$logoColor: #f83821;

$linkHover: #db7286;

$h2FontSize: 120%;
$commonTextSize: smaller;

$thumbLinkHover: black;
$thumbOverlayOpacity: 0.60;
$thumbOverlayScale: 1.1;
$thumbOverlayAnimDuration: .5s;

// applied scheme
$black: nth($scheme, 1);
$color1: nth($scheme, 2);
$color2: nth($scheme, 3);

// basics
$white: #fff;
$grey: #423d3d;
